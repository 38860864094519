.wrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000001c;
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    padding: 30px 44px;
    min-width: 394px;
    margin: 10px 0;
  }
  
  .middle_heading {
    // font: normal normal bold 16px/18px Arial;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.11px;
    margin: 0;
    text-align: center;
    color: #000000;
  }
  
  .middle_sub_heading {
    // font: normal normal bold 16px/18px Arial;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.11px;
    color: #e37617;
    text-align: center;
    margin: 10px 0 30px;
  }
  
  .label {
    margin: 0 0;
    padding: 10px !important;
    // font: normal normal 300 14px/16px Arial;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.1px;
    color: #000000;
  }
  
  .input {
    border-radius: 0;
    fieldset {
      border: 0.25px solid #555858;
    }
  }
  
  .sub_text_btn {
    text-align: right;
    // font: normal normal bold 15px/18px Arial;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.1px;
    color: #661c69;
    margin: 0px 0 0 0;
      height: auto;
      display: block;
      margin-right: 0;
      margin-left: auto;
      padding: 18px 10px;
  }
  
  .btn_wrapper {
    margin-top: 60px;
  }
  
  .btn_wrapper_pass {
    margin-top: 20px;
  }
  
  .imgRegister {
    width: 249px;
    height: 267px;
  }
  
  .right_wrapper {
    padding: 0 20px;
    margin-top: 15px;
    h4 {
      // font: normal normal bold 15px/18px Arial;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0.11px;
      color: #000000;
      margin-bottom: 0;
      width: 250px;
    }
    .privacy {
      width: 192px;
      margin-bottom: 30px;
      button {
        height: auto;
        padding: 0;
        line-height: 0;
        color: #e37617;
      }
    }
    .need_help {
      padding: 0;
      margin: 0;
    }
  }
  
  .bottom_image {
    position: absolute;
    width: 288px;
    height: 290px;
    bottom: 0;
    left: 0;
  }
  
  .info_otp{
      color: #E37617;
      margin: 5px 0 0;
  }
  
  .otp_text{
      margin: 20px 0 0;
  }
  
  .canvasCustom{
    margin: 30px 0 10px;
    div:nth-of-type(1){
      display: flex;
    }
    canvas{
      width: 85%;
    }
    a{
      width: 100%;
      text-align: center;
    }
  }